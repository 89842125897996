
    .student-breadcrumb {
        font-size: 14px;
    }
    .operationPromotion-container {
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - 120px);
        box-sizing: border-box;
        .title {
            font-size: 20px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #333333;
            margin: 20px 0;
            line-height: 1;
        }
        .promotion-table {
            min-height: calc(100vh - 195px);
            width: 100%;
            background: #fff;
            /deep/ .el-tabs {
                min-height: calc(100vh - 215px);
                padding: 20px 0 0 20px;
                .el-tabs__content {
                    min-height: calc(100vh - 299px);
                    .el-tab-pane {
                        min-height: calc(100vh - 299px);
                    }
                }
                /deep/ .el-tabs__header {
                    /*margin: 0 0 32px;*/
                    .el-tabs__active-bar {
                        background-color: #FD4446;
                    }
                    .el-tabs__nav-wrap::after {
                        height: 1px;
                        background-color: #DFDFDF;
                    }
                    .el-tabs__item {
                        font-size: 20px;
                        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                        font-weight: 400;
                        color: #333333;
                        /*margin-bottom: 2px;*/
                    }
                    /deep/.el-tabs__item{
                        line-height: 29px;
                    }
                    .el-tabs__nav .is-active {
                        color: #FD4446;
                    }
                }
            }
        }
    }
    .promotion-txt{
        display: flex;
        justify-content: space-between;
        color: #333333;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-size: 20px;
        font-weight: 500;
        line-height: 1;
        margin: 20px 0;
        span:nth-child(2){
            font-size: 18px;
            color: #FD4446;
            font-family: Avenir, Helvetica, Arial, sans-serif;
        }
        b{
            font-size: 12px;
            margin: 0 2px;
            font-weight: 500;
        }
    }
